'use client'
import { cx } from 'class-variance-authority'
import Button from '../Button'
import { TEmptyStateProps } from './EmptyState.type'
import Image from 'next/image'

function EmptyState(props: TEmptyStateProps) {
  const {
    imageUrl,
    title,
    description,
    primaryActionText,
    secondaryActionText,
    primaryActionParams,
    secondaryActionParams,
    onClickPrimaryAction,
    onClickSecondaryAction,
    className,
    titleClassName,
    descClassName,
    ...rest
  } = props
  return (
    <div className={cx('flex max-w-[560px] flex-col items-center', className)} {...rest}>
      {imageUrl && (
        <div className="mb-6 h-[225px] max-w-[390px]">
          <Image
            className="h-full w-full object-contain"
            src={imageUrl}
            alt={`image-${imageUrl}`}
            width={390}
            height={225}
          />
        </div>
      )}

      <div className={cx(titleClassName, 'text-body-lg-bold mb-4 text-tertiary500')}>{title}</div>
      {description && (
        <div className={cx(descClassName, 'text-caption-lg-regular mb-6 text-center text-tertiary300')}>
          {description}
        </div>
      )}
      <div className="flex gap-6">
        {secondaryActionText && (
          <Button
            className={cx('!min-w-[260px]', secondaryActionParams?.btnClassName)}
            onClick={onClickSecondaryAction}
            IconLeft={secondaryActionParams?.IconLeft}
            IconRight={secondaryActionParams?.IconRight}
            disabled={secondaryActionParams?.disableBtn}
            variant={secondaryActionParams?.variantBtn ?? 'outline2'}
            size={secondaryActionParams?.btnSize}
          >
            {secondaryActionText}
          </Button>
        )}
        {primaryActionText && (
          <Button
            className={cx('!min-w-[260px]', primaryActionParams?.btnClassName)}
            onClick={onClickPrimaryAction}
            IconLeft={primaryActionParams?.IconLeft}
            IconRight={primaryActionParams?.IconRight}
            disabled={primaryActionParams?.disableBtn}
            variant={primaryActionParams?.variantBtn}
            size={primaryActionParams?.btnSize}
          >
            {primaryActionText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default EmptyState
