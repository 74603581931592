"use client"
import { captureException } from "@sentry/nextjs"
import Link from "next/link"
import { useRouter } from "next/navigation"
import { useEffect } from "react"
import ErrorPageIllus from "shared-assets/images/blacklist-error.webp"
import { ErrorPage } from "shared-ui"
import { getUserInfo } from "shared-utils/authentication"

import { ZENDESK_URL } from "@/config/client"

export default function ErrorBoundaryPage({
  error,
}: {
  error: Error & { digest?: string }
}) {
  const router = useRouter()
  const session = getUserInfo()

  useEffect(() => {
    captureException(error)
  }, [error])

  const handleOnClick = () => {
    if (session?.user?.id) {
      router.replace("/")
      return
    }
    router.back()
  }

  const pageDescription = (
    <div>
      Coba beberapa saat lagi, atau hubungi{" "}
      <Link
        href={ZENDESK_URL}
        target="_blank"
        className="font-semibold !text-secondary500 hover:underline"
      >
        pusat bantuan
      </Link>
    </div>
  )

  return (
    <ErrorPage
      title="Mohon Maaf, Terjadi Kesalahan"
      desc={pageDescription}
      imageUrl={ErrorPageIllus.src}
      onClickPrimaryAction={handleOnClick}
    />
  )
}
