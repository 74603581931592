import EmptyState from '../EmptyState'
import type { TErrorPageProps } from './ErrorPage.type'

const pageDesc = (
  <div>
    Halaman yang Anda cari tidak dapat ditemukan. <br /> Silakan kembali ke halaman utama.
  </div>
)

const ErrorPage = ({
  title = 'Halaman Tidak Tersedia',
  desc = pageDesc,
  imageUrl,
  onClickPrimaryAction,
}: TErrorPageProps) => {
  return (
    <div className="flex h-screen items-center justify-center text-center">
      <EmptyState
        imageUrl={imageUrl}
        title={title}
        description={desc}
        primaryActionText="Kembali"
        onClickPrimaryAction={onClickPrimaryAction}
        className="!max-w-full pb-13"
        primaryActionParams={{
          btnClassName: '!min-w-[auto] !px-3',
          btnSize: 'small',
        }}
      />
    </div>
  )
}

export default ErrorPage
